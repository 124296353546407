.accordion-questions {
  margin-bottom: 42px;
  &__title {
    color: $primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &__desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $grayscale-400;
  }

  .MuiPaper-root.MuiPaper-elevation {
    &::before {
      background-color: transparent !important;
    }
  }
}
