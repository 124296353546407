.header {
  position: relative;
  &__content {
    width: 100%;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 13px 50px 37px;
    background: $primary;
    color: $white;
    border-radius: 0 0 30px 30px;
    font-family: "Montserrat", sans-serif;
    @include _desktop {
      display: flex;
      padding: 32px 16px;
      min-height: max-content;
    }
  }
  .container {
    @include _desktop {
      padding: 0;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0;

    @include _desktop {
      padding: 0;
    }
  }

  &__logo {
    line-height: 1;
    a {
      color: $white;
      &:hover {
        opacity: 1;
      }
    }
  }

  &__contacts {
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 16px 16px 16px 50px;
  }

  &__contacts-city {
    max-width: 175px;
    margin-right: 40px;
  }

  &__contacts-numbers {
    display: flex;
    flex-direction: column;
    gap: 4px;

    a {
      color: $white;
    }
  }

  &__nav-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__nav-link {
    color: $white;
  }
  &__nav-item {
  }

  .burger {
    width: 32px;
    height: 22px;
    margin-right: 6px;
    z-index: 100;
  }
  .burger-menu {
    height: 100%;
    width: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
  }

  .burger-bar {
    width: 32px;
    height: 2px;
    background-color: #fff;
    border-radius: 0.5em;
  }

  /* ------------- sliding menu ------------------ */

  /* ----- animations--------- */
  /* clicked */
  .burger-bar.clicked:nth-child(1) {
    transform: rotate(45deg) translate(12px, 2px);
    transition: ease-out 0.5s;
  }

  .burger-bar.clicked:nth-child(2) {
    transform: scale(0.1);
    transition: ease-out 0.5s;
  }

  .burger-bar.clicked:nth-child(3) {
    transform: rotate(135deg) translate(-12px, 2px);
    transition: ease-out 0.5s;
  }

  /* unclicked */
  .burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  }

  .mobile-menu {
    width: 100%;
    height: 100vh;
    background: $primary;
    position: absolute;
    top: 0;
    z-index: 50;
    color: $white;

    &.hidden {
      display: none;
    }

    &.visible {
      display: inherit;
      overflow: auto;
    }

    &__top {
      padding: 22px 16px 32px 16px;
      width: max-content;
    }

    &__list {
      margin-bottom: 24px;
    }

    &__footer {
      padding: 16px 16px 32px 16px;
      border-top: 1px solid rgba(88, 160, 205, 1);
    }

    &__social {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      z-index: 100;
    }

    &__vk {
    }

    &__city {
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
      }
    }

    &__numbers {
      display: flex;
      flex-direction: column;
      gap: 16px;
      //styleName: Mobile/Header/Small/Bold;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;

      a {
        color: $white;
      }
    }

    &__link {
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: inherit;
    }

    &__item {
      padding: 16px;

      &:hover {
        background: linear-gradient(180deg, #3f789b 0%, #58a0cd 100%);
      }
    }

    &__submenu {
    }
  }
  .header {
    &__login {
    }
  }
}
