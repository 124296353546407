.ui-link {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  opacity: 1;
  transition: opacity 0.2s easy-in-out;
  color: $primary;

  &:hover {
    opacity: 0.9;
  }
  &--centered {
    margin: 0 auto;
  }

  &.dark {
    color: $primary;
  }
  &.primary {
    background: $green-light;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    &__arrow {
      background: $green-light;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  &.light {
    color: $white;

    &__arrow {
      color: $white;
    }
  }

  &__label {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  &__arrow {
    width: 8px;
    height: 25px;
  }
}
