.home-reviews {
  padding-top: 64px;
  padding-bottom: 76px;
  background-color: $white;
  border-radius: 0 0 85px 85px;

  @include _desktop {
    margin-top: 0;
    border-radius: 0;
    background: $bg-grey;
    padding-top: 24px;
    padding-bottom: 0;
  }

  &__title {
    font-size: 42px;
    font-weight: 700;
    line-height: 48px;
    color: $primary;
    margin-bottom: 34px;

    @include _desktop {
      color: $primary;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  &__content {
    margin-bottom: 48px;

    @include _phablet {
      margin-bottom: 8px;
    }
  }

  &__item {
    border: 2px solid rgba(52, 78, 94, 1);
    padding: 20px;
    border-radius: 30px;
    overflow: hidden;
  }

  &__item-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__item-info {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}
