.home-booking {
  padding-top: 24px;
  margin-bottom: 64px;
  font-family: "Montserrat", sans-serif;

  @include _tablet {
    margin-bottom: 0;
    padding-bottom: 24px;
  }

  .skeleton {
    width: 210px;
    height: 275px;

    &-md {
      width: 48%;
      height: 48.6%;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 30px;

      @include _phablet {
        width: 48.9%;
        height: 47.9% !important  ;
      }
    }
    &-sx {
      @include _phablet {
        width: 100%;
        height: 132px;
        border-radius: 30px;
      }
    }
    &-form {
      width: 458px;
      height: 560px;
    }
  }

  &__bottom {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    min-height: 132px;

    .home-booking-block--image {
      img {
        border-radius: 30px;
      }
    }
  }

  .captcha-error-text {
    color: $red-300;
    font-size: 14px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    min-height: 560px;

    @include _desktop {
      flex-direction: column-reverse;
      gap: 16px;
    }
  }
  &__preview {
    max-width: 909px;
    height: 560px;
    max-width: fit-content;
    display: grid;
    grid-column-gap: 8px;
    grid-row-gap: 10px;
    grid-template-areas:
      "car descount"
      "car image-block";

    @include _desktop {
      height: 100%;
      margin: 0 auto;
    }

    @media (max-width: 700px) {
      display: flex;
      flex-direction: column;
      max-height: 100%;
      max-width: 100%;
      margin: 0;
    }
    @include _phablet {
      gap: 8px;
    }
  }

  &__car {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 438px;

    @media (max-width: 700px) {
      max-height: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  &__preview-left {
    grid-area: car;
    position: relative;
    width: 100%;
    height: 560px;
    min-width: 438px;

    @include _desktop {
      height: 100%;
    }
    @media (max-width: 700px) {
      max-width: 100%;
      min-width: 320px;
      max-height: max-content;
      height: max-content;
      display: flex;

      img {
        height: auto !important;
      }
    }

    .home-booking-block {
      width: 48%;
      height: 48.6%;
      position: absolute;
      bottom: 0;
      right: 0;

      @include _desktop {
        width: 46.9%;
        height: 47.8%;
      }
      @media (max-width: 700px) {
        font-size: 14px;
        width: 48.9%;
        height: 47.9%;
      }

      // @include _phablet {
      //   padding: 24px 20px;
      // }
    }
  }

  &__captcha {
    margin-bottom: 16px;
  }

  &__form {
    color: $white;
    max-width: 468px;
    width: 100%;
    border-radius: 30px;
    padding: 20px 40px;

    @media (max-width: 1050px) {
      overflow: hidden;
      padding: 20px;

      .smart-captcha {
        min-width: 200px;
      }
    }
    @include _desktop {
      width: 100%;
      max-width: 100%;
      padding: 20px 30px;
    }

    @include _phablet {
      .ui-button {
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
      }
    }

    background: $green-light;

    &.error {
      .smart-captcha {
        border: 2px solid red;
        border-radius: 11px;
        overflow: hidden;
        margin-bottom: 6px;
      }
    }
  }

  // mui
  &__field.MuiFormControl-root {
    margin-bottom: 16px;
    .MuiFormHelperText-root {
      &.Mui-error {
        color: $red-300;
        font-size: 14px;
      }
    }

    .MuiInputBase-root {
      background-color: $white;
      border-radius: 8px;

      &.Mui-focused {
        fieldset {
          border-color: $white;
          border-width: 1px;
        }
      }
      &.Mui-error {
        fieldset {
          border-color: $red-300;
          border-width: 2px;
        }
      }

      .MuiInputBase-input {
        color: $text;
        padding: 12px 18px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;

        &::placeholder {
          color: $grayscale-400;
          opacity: 1;
        }
      }
    }
  }

  &__form-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 32px;
  }

  &__form-btn {
    margin: 0 auto;
    margin-bottom: 16px;
  }

  &__form-desc {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
}

.home-booking-block {
  width: 210px;
  height: 280px;
  padding-top: 42px;
  padding-left: 34px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #fff;
  background: $secondary;
  border-radius: 30px;

  @include _phablet {
    height: 132px;
    padding: 24px 20px;
  }

  &[descount] {
    grid-area: descount;
  }
  &[image-block] {
    grid-area: image-block;
  }
  &__icon {
    width: 32px;
    height: 32px;
  }

  &__text {
    width: 125px;
    @include _phablet {
      font-size: 14px;
      line-height: 18px;
      width: 100%;
    }
  }

  &--image {
    width: 210px;
    height: 270px;
    background: none;
    position: relative;

    @include _phablet {
      height: 132px;

      img {
        height: 276px;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
