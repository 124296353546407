.category {
  .banner__container {
    padding-top: 96px;
    @include _desktop {
      padding-top: 32px;
      padding-bottom: 20px;
    }
  }
  .locations {
    padding-top: 96px;

    @include _desktop {
      padding-top: 0;
    }
  }
}
