@mixin bg($size: 'contain', $position: 'center') {
  background-size: #{$size};
  background-position: #{$position};
  background-repeat: no-repeat;
}

@mixin border_gradient_color($gradient) {
  background: $gradient;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

@mixin btn_anim($scaleMax: 1.05, $scaleMin: 0.95) {
  transform-origin: center center;
  transition: all ease-out 240ms;

  &:hover {
    transform: scale(#{$scaleMax});
  }

  &:focus {
    outline: transparent;
  }

  &:focus-visible {
    transform: scale(#{$scaleMax}) translateY(-5%);
  }

  &:active {
    transform: scale(#{$scaleMin});
  }
}

@mixin no-btn($display: 'inline-block') {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
  display: #{$display};
}

@mixin _desktop {
  @media (max-width: 960px) {
    @content;
  }
}
@mixin _tablet {
  @media (max-width: 850px) {
    @content;
  }
}
@mixin _phablet {
  @media (max-width: 600px) {
    @content;
  }
}
@mixin _mobile {
  @media (max-width: 480px) {
    @content;
  }
}
