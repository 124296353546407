.about {
  padding-top: 40px;
  position: relative;
  z-index: 20;
  padding-bottom: 78px;
  border-radius: 0 0 85px 85px;
  background-color: $bg-grey;

  @include _tablet {
    padding-top: 20px;
  }
  &__title {
    margin-bottom: 38px;
    @include _phablet {
      padding-top: 18px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__stack {
    max-width: 560px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--big {
      max-width: 906px;
    }
    &-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      list-style: circle;
    }
    &-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
    &-item {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      list-style: none;
      position: relative;
      &.numbers {
        padding-left: 20px;
        span {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      &.pointer {
        padding-left: 15px;

        &::before {
          content: '•';
          position: absolute;
          left: 0;
          top: 0;
          font-size: 12px;
        }
      }
    }
    a,
    &-link {
      color: #6a5ff0;
      text-decoration: none;
    }
    &-subtitle {
      color: $text;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
