.footer {
  position: relative;
  margin-top: -85px;
  background: $primary;
  color: $white;
  height: 265px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: "Montserrat", sans-serif;

  &__title {
    font-size: 28px;
    font-weight: 700;
  }
  @include _tablet {
    margin-top: 0;
    height: 186px;
  }

  &.fixed {
    width: 100%;
    position: fixed;
    bottom: 0;
  }

  &__flag {
    position: absolute;
    top: -98px;
    left: 50px;

    @include _tablet {
      top: -25px;
      left: auto;
      right: 17px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    padding-bottom: 67px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    @include _tablet {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 32px 0;
      gap: 32px;
    }
  }

  &__logo {
    width: 148px;
    height: 40px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 32px;

    a {
      text-decoration: none;
      color: $white;
    }
    @include _tablet {
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;

      a {
        border-bottom: 1px solid $white;
      }
    }
  }
}
