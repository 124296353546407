.home-cars {
  padding-top: 68px;
  background-color: #fff;

  @include _desktop {
    padding-top: 0;
  }
  .banner {
    @include _desktop {
      padding-bottom: 0;
    }
  }
  &__content {
    padding-top: 47px;
    padding-bottom: 90px;

    @include _phablet {
      padding-bottom: 60px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 78px;
    margin-bottom: 48px;

    @include _phablet {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 70px;
      margin-bottom: 32px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    @include _phablet {
      gap: 8px;
    }
  }

  &__item-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
}
