.schedule {
  padding-top: 40px;
  @include _phablet {
    padding-top: 20px;
  }
  &__container {
    margin-bottom: 140px;
    @include _phablet {
      margin-bottom: 44px;
      padding: 0;
    }
  }

  &__title {
    @include _phablet {
      & > .section__title {
        color: $primary;
      }
    }
  }
  .ui-title {
    margin-bottom: 44px;
    @include _phablet {
      margin-bottom: 20px;
      padding: 0 16px;
    }
  }
}
