.locations {
  position: relative;
  z-index: 20;
  padding-bottom: 93px;
  border-radius: 0 0 85px 85px;
  background-color: $bg-grey;

  @include _desktop {
    border-radius: 0;
    padding-bottom: 24px;
  }

  .container {
    padding: 0;
  }
  .ui-title {
    margin-bottom: 45px;
    @include _tablet {
      margin-bottom: 36px;
      padding-left: 16px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;

    @include _tablet {
      flex-direction: column;
      padding: 0;
    }
  }

  &__list {
    max-width: 312px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include _tablet {
      padding-left: 16px;
      margin-bottom: 24px;
    }
  }

  &__item {
    p {
      max-width: 312px;
    }
  }

  &__item-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }

  &__item-info {
    width: max-content;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &__item-schedule {
    color: $grayscale-400;
    font-weight: 500;
  }
}
