.cars {
  padding-top: 40px;
  position: relative;
  z-index: 20;
  padding-bottom: 41px;
  border-radius: 0 0 85px 85px;
  background-color: $bg-grey;

  @include _tablet {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  &__content {
    font-weight: 500;
    text-align: center;
  }
  &__title {
    margin-bottom: 40px;
    @include _tablet {
      margin-bottom: 20px;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 40px;
    grid-column-gap: 24px;
    min-height: 520px;

    @include _desktop {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _tablet {
      grid-row-gap: 24px;
    }
    @include _phablet {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    max-height: 260px;
  }

  &__item-preview {
    height: 184px;
    margin-bottom: 19px;

    @include _tablet {
      margin-bottom: 24px;
    }
  }

  &__item-title {
  }

  &__item-details {
    span:not(:last-child)::after {
      content: ', ';
    }
  }
}
