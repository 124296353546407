.schedule-table {
  position: relative;
  width: max-content;
  margin: 0 auto;
  padding: 49px 40px;
  border: 2px solid rgba(63, 120, 155, 1);
  border-radius: 30px;
  color: $primary;

  @include _desktop {
    border: 0;
    border-radius: 0;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  &__captcha {
    margin-bottom: 20px;
  }
  &__form {
    .ui-button {
      margin-left: auto;
    }
    .smart-captcha {
      width: 100%;
      min-width: 250px !important;
      margin: 0 auto;
    }

    &.error {
      .smart-captcha {
        border: 2px solid red;
        border-radius: 11px;
        overflow: hidden;
        margin-bottom: 6px;
      }
    }
  }

  &__field.MuiFormControl-root {
    margin-bottom: 8px;
    .MuiFormHelperText-root {
      &.Mui-error {
        color: $red-300;
        font-size: 14px;
      }
    }

    .MuiInputBase-root {
      background-color: $grayscale-100;
      border-radius: 8px;
      &.Mui-focused {
        fieldset {
          border-color: $grayscale-100;
          border-width: 1px;
        }
      }
      &.Mui-error {
        fieldset {
          border-color: $red-300;
          border-width: 2px;
        }
      }

      .MuiInputBase-input {
        color: $text;
        padding: 12px 18px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;

        &::placeholder {
          color: $grayscale-400;
          opacity: 1;
        }
      }
    }
  }

  &__tbody {
    width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__tr {
    display: grid;
    grid-template-columns: 160px 140px 120px 314px 154px;
    gap: 24px;
  }
  &__td {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 48px;
    background-color: $white;
    padding: 12px 16px;
    text-align: center;
    font-size: 15px;
  }

  &__action {
  }
}
