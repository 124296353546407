.gallery {
  padding: 40px 0;
  position: relative;
  z-index: 20;
  border-radius: 0 0 85px 85px;
  background-color: $bg-grey;
  @include _desktop {
    border-radius: 0;
    padding-top: 20px;
    padding-bottom: 24px;
  }
  .MuiPaper-root.MuiPaper-elevation {
    width: 800px;
  }
  &__title {
    margin-bottom: 40px;
  }

  &__content {
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 33px;
    overflow: hidden;
    @include _tablet {
      min-height: 750px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include _phablet {
      min-height: 500px;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 8px;
    }
  }

  &__item {
    max-height: 184px;
  }

  &__modal {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
      position: relative;
      overflow: visible;
    }
  }
  &__modal-arrow {
    position: absolute;
    z-index: 1000;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &.left {
      left: -45px;
    }
    &.right {
      right: -45px;
    }
  }
  &__modal-content {
    user-select: none;
  }

  &__modal-preview {
    max-height: 350px;
  }
}
