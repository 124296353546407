.banner {
  border-radius: 30px;
  height: 236px;
  position: relative;
  overflow: hidden;

  @include _tablet {
    height: 100%;
  }
  a {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include _tablet {
      gap: 17px;
    }
    &:hover {
      opacity: 1;
    }
  }

  &--blue {
    background: $blue-100;
    .banner__title {
      color: $primary;
    }
    .ui-button {
      background: $primary !important;
    }
    .banner__link {
      color: rgba(63, 120, 155, 1);
    }
  }
  &--green {
    background: $primary;

    .banner__title,
    .banner__link {
      color: $grayscale-500;
    }
    .banner__link:after {
      background-color: $grayscale-500;
    }
  }
  &__content {
    width: 100%;
    padding: 38px 0 42px 48px;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1px;

    @include _tablet {
      padding: 10px 17px 12px;
    }
  }

  &__content-head {
    position: relative;
    display: flex;
    max-width: 490px;
    width: 100%;
    margin-bottom: 36px;

    @include _tablet {
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0;
      height: 100%;
    }
  }

  &__title {
    color: $primary;
    font-size: 31px;
    @include _phablet {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 26px;
    }
  }

  &__link {
    position: absolute;
    text-decoration: none;
    color: $violet-200;

    @include _tablet {
      display: none;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $violet-200;
    }
  }
  &__disabled-btn {
    position: absolute;
    bottom: -7px;
    right: 0;

    @include _tablet {
      display: none !important;
    }
  }

  &__mobile-btn.ui-button {
    display: none !important;
    align-items: center;
    justify-content: center;

    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
    }

    @include _tablet {
      display: flex !important;
      padding: 0;
    }
  }

  &__preview {
    max-width: 472px;
    width: 100%;
    height: 236px;
    line-height: 0;

    @include _tablet {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
