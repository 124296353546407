.category-page {
  position: relative;
  z-index: 30;
  border-radius: 0 0 85px 85px;
  background-color: $bg-grey;
  padding-bottom: 90px;

  &__container {
    padding-left: 50px;
  }

  @include _desktop {
    border-radius: 0;
    background: $bg-grey;
    padding-top: 0;
    padding-bottom: 24px;
  }

  &__title {
    margin-bottom: 24px;

    @include _phablet {
      background-color: $white;
      padding: 20px 16px;
    }
  }

  &__content {
    display: flex;
    align-items: flex-end;
    gap: 25px;

    @include _phablet {
      background-color: $white;
      margin: 16px 16px 32px;
    }
  }

  &__content-left {
    max-width: 568px;
    width: 100%;
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 500;

    @include _phablet {
      max-width: 100%;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      background-color: $white;
      padding: 20px 16px;
      margin: 16px;
      border-radius: 30px;
    }
  }

  &__text {
    max-width: 600px;
    width: 100%;
    margin-bottom: 32px;

    @include _phablet {
      background-color: $white;
      padding: 20px 16px;
      border-radius: 30px;
    }
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 24px;
    color: $primary;
  }

  &__info {
    margin-bottom: 40px;
  }

  &__info-title {
    font-weight: 500;
  }

  &__list {
    list-style: inside;
  }

  &__item {
    list-style: inside;
    padding-left: 10px;
  }

  &__info-subtitle {
  }

  &__content-right {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__time {
    max-width: 398px;
    width: 100%;
    padding: 20px;
    border: 2px solid $red-200;
    border-radius: 30px;
  }

  &__time-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.10000000149011612px;
    color: $primary !important;
    margin-bottom: 30px;
  }

  &__time-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
}
