.category-hero {
  .captcha-error-text {
    color: $red-300;
    font-size: 14px;
  }
  &__container {
    padding-top: 22px;
    display: flex;
    gap: 8px;
    margin-bottom: 64px;
    @include _desktop {
      flex-direction: column-reverse;
    }
    @include _phablet {
      margin-bottom: 44px;
    }
  }
  &__preview {
    position: relative;
    min-width: 650px;
    height: 560px;
    @include _desktop {
      min-width: 100%;
      width: 100%;
      height: 100%;
    }

    @include _phablet {
      min-height: 275px;

      img {
        height: auto;
      }
    }
  }

  .skeleton {
    border-radius: 30px;

    &-sx {
      width: 32.3%;
      height: 47.6%;
      position: absolute;
      right: 0;
      bottom: 0;

      @media (max-width: 700px) {
        width: 48.9%;
        height: 47.8%;
        bottom: 6px;
      }
    }

    &-md {
      width: 100%;
      height: 100%;
      min-height: 565px;
    }
  }
  &__block {
    width: 32.3%;
    height: 47.6%;
    background: $secondary;
    padding: 25px 23px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    color: $white;
    gap: 16px;
    position: absolute;
    right: 0;
    bottom: 0;

    @media (max-width: 700px) {
      bottom: 6px;
      font-size: 14px;
      width: 48.9%;
      height: 47.8%;
      padding: 15px 20px;
    }
  }
  &__icon {
    width: 32px;
    height: 32px;
  }

  &__label {
    font-size: 16px;
    line-height: 24px;

    @include _desktop {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }
  }

  &__form {
    width: 100%;
    border-radius: 30px;
    background: linear-gradient(180deg, #37dfc1 0%, #1bd073 100%);
    padding: 20px 40px;
    color: $white;

    @media (max-width: 1050px) {
      overflow: hidden;
      padding: 20px;

      .smart-captcha {
        min-width: 200px;
      }
    }
    @include _desktop {
      width: 100%;
      max-width: 100%;
      padding: 20px 30px;
    }

    @include _phablet {
      .ui-button {
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
      }
    }

    &.error {
      .smart-captcha {
        border: 2px solid red;
        border-radius: 11px;
        overflow: hidden;
        margin-bottom: 6px;
      }
    }
  }

  &__form-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 32px;

    br {
      @include _tablet {
        display: none;
      }
    }
  }

  &__form-btn {
    margin: 0 auto;
    margin-bottom: 16px;
  }

  &__form-desc {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
}
