.banner-badge {
  font-family: "Montserrat", sans-serif;
  max-width: 229px;
  width: 100%;
  padding: 6px 21px;
  height: 32px;
  background-color: #fc4b75;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #f3f8fa;
  border-radius: 8px;
}
