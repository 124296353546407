.submenu {
  position: relative;
  overflow: hidden;
  &__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    &:hover {
      background: linear-gradient(180deg, #3f789b 0%, #58a0cd 100%);
    }
  }
  &__arrow {
    transition: transform 0.2s ease-in-out;
    &.open {
      transform: rotate(180deg);
    }
  }
  &__list {
    position: relative;
    top: 100%;
    left: 0;
    z-index: 9999;
    display: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    transform-origin: top;
    transition: opacity 0.3s, transform 0.3s;

    &.open {
      display: block;
      opacity: 1;
      transform: scaleY(1);
    }
  }
  &__item {
    padding: 10px 32px;
    color: #fff;

    a {
      color: inherit;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: 0em;
    }
    &:hover {
      background: linear-gradient(180deg, #3f789b 0%, #58a0cd 100%);
    }
  }
}
