.home-schedule {
  position: relative;
  z-index: 20;
  background: $bg-grey;
  padding-top: 134px;
  padding-bottom: 37px;
  border-radius: 0 0 85px 85px;
  margin-top: -85px;

  @include _desktop {
    margin-top: 0;
    border-radius: 0;
    background: $bg-grey;
    padding-top: 0;
    padding-bottom: 24px;
  }
  .container {
    padding: 0;
  }
  &__title {
    margin-bottom: 44px;
    padding-left: 48px;
    @include _desktop {
      color: $primary !important;
      padding-left: 16px;
      margin: 20px 0;
    }
  }
  &__link {
    width: max-content;
    margin: 0 auto;
    color: $primary;
    .ui-link {
      padding-top: 35px;
      color: $primary;
      @include _desktop {
        color: $primary;
        padding-top: 24px;
      }
      &__label {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
      }
    }
  }
}
