.collaborate {
  padding-top: 40px;
  position: relative;
  z-index: 20;
  padding-bottom: 78px;
  border-radius: 0 0 85px 85px;
  background-color: $bg-grey;

  @include _phablet {
    padding-top: 20px;
    padding-bottom: 40px;

    .container {
      padding: 0;
    }
    .home-booking__form {
      border-radius: 0;
    }
  }
  .captcha-error-text {
    color: $red-300;
    font-size: 14px;
  }
  .ui-button {
    margin: 0 auto;
    margin-bottom: 16px;
  }
  &__title {
    margin-bottom: 24px;
    .section__title {
      @include _phablet {
        padding: 0 16px;
      }
    }
  }
  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    padding-left: 50px;

    @include _desktop {
      padding-left: 0px;
    }

    @include _tablet {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__desc {
    max-width: 507px;
    width: 100%;

    @include _phablet {
      padding: 0 16px;
    }
  }

  &__form {
    background: $primary;
    padding: 48px 40px;
    color: $white;
    border-radius: 30px;

    @include _phablet {
      border-radius: 0;
    }

    textarea {
      height: 176px;
      border-radius: 8px;
      resize: none;
    }
  }

  &__form-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 32px;
  }

  &__form-desc {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
}
