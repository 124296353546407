.instructors {
  padding-top: 40px;
  position: relative;
  z-index: 20;
  padding-bottom: 41px;
  border-radius: 0 0 85px 85px;
  background-color: $bg-grey;

  @include _tablet {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  &__title {
    margin-bottom: 50px;

    @include _tablet {
      margin-bottom: 20px;
    }
  }

  &__content {
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 350px);
    justify-content: center;
    grid-column-gap: 76px;
    grid-row-gap: 56px;
    min-height: 520px;

    @include _tablet {
      grid-template-columns: repeat(1, 400px);
      grid-row-gap: 76px;
      img {
        object-position: top;
      }
    }

    @include _mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__items {
    height: 472px;

    &.skeleton {
      background-color: #fff;
    }
  }

  &__items-preview {
    width: 100%;
    height: 328px;
    margin-bottom: 16px;
  }

  &__items-image {
    object-fit: cover;
  }

  &__items-content {
  }

  &__items-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
  }

  &__items-info {
  }
}
