.popup {
  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: opacity 0.3 ease-in-out;
    &:hover {
      opacity: 0.9;
    }
  }

  &__menu {
    & .MuiList-root {
      background: $primary;
      padding: 10px;

      a {
        color: $white;
      }
    }
  }

  &__item {
    &.MuiButtonBase-root.MuiMenuItem-root {
      height: 36px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border-radius: 4px;
      &:hover {
        background: linear-gradient(180deg, #344e5e 0%, #4c768f 100%);
      }
    }
  }
}
