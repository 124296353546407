* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
// html {
//   scroll-behavior: smooth;
//   padding-left: calc(100vw - 100%);
//   padding-right: 0;
// }
body {
  background-color: $bg-grey;
  color: $grayscale-500;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
span,
ul {
  font-family: "Montserrat", sans-serif;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container {
  max-width: 1132px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @include _tablet {
    padding: 0 16px;
  }
}

.section {
  &__title {
    font-family: "Montserrat";
    font-size: 56px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: $primary;

    @include _tablet {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
    }
    &.light {
      color: $white;
    }
  }
}

a {
  transition: opacity 0.3 ease-in-out;
  &:hover {
    opacity: 0.9;
  }
}

.ui-modal.MuiDialog-root.MuiModal-root {
  .MuiPaper-root {
    max-width: 534px;
    width: 100%;
  }

  .MuiTypography-root {
    display: flex;
    align-items: center;
    gap: 16px;
    font-family: "Montserrat", sans-serif;
  }

  .MuiTypography-h6 {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: $text;
    padding: 24px 50px 8px 24px;
  }

  .MuiDialogContent-root.text-padding {
    .MuiDialogContentText-root {
      padding-left: 40px;
    }
  }
  .MuiDialogContentText-root {
    color: $grayscale-400;
    padding: 0 0 20px 0;
  }

  .MuiDialogActions-root {
    padding: 0 24px 24px 24px;
    text-align: left;
  }

  .ui-button {
    &--green {
      background-color: $green-300;
    }
  }
}
