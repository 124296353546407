.ui-button {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  border: 0;
  background: $secondary;
  padding-left: 32px;
  padding-right: 32px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    vertical-align: text-top;
    text-align: center;
    span {
      margin: 0;
      line-height: 1;
    }
    svg {
      padding-bottom: 1px;
    }
  }
  &--large {
    height: 48px;
  }
  &--medium {
    height: 44px;
  }
  &--small {
    height: 40px;
  }
  &--disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.8;
    user-select: none;
  }
  &--primary {
    background: $secondary;
    color: $white;
  }
  &--green {
    background: $green-light;
    color: $white;
  }
  &--red {
    background: $red;
    color: $white;
  }
  &--light {
    color: $primary;
    background: $white;
    transition: all 0.3s ease-in-out;
    p {
      background: $primary;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &:hover {
      p {
        background: $primary;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
}
