.ui-title {
  background-color: $white;
  padding: 20px 48px;
  border-radius: 12px;

  @include _tablet {
    background-color: transparent;
    padding: 0;
  }
}
