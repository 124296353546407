$primary: rgba(51, 78, 95, 1);
$white: #fff;

$secondary: linear-gradient(180deg, #ff5757 0%, #e83f3c 100%);
$red: linear-gradient(180deg, #e16146 0%, #f64240 100%);
$green-light: linear-gradient(180deg, #37dfc1 0%, #1bd073 100%);

// solids
$text: #202020;
$grayscale-500: #202020;
$grayscale-400: #6e6e7c;
$grayscale-300: #ababb5;
$grayscale-200: #d5d5de;
$grayscale-100: #ebebf0;

$violet-200: #37dfc1;
$violet-300: #472b82;

$green-300: #2ad01b;

$red-300: #ee2c33;
$red-200: #fc4b75;
$red-100: #ff6c6c;

$blue-100: #f3f8fa;

$bg-grey: #f3f8fa;

// gradients
$violet-gr300: linear-gradient(180deg, #6562ef 0%, #9f32fa 100%);
$violet-gr200: linear-gradient(180deg, #8881ff 0%, #c751ff 100%);
$violet-gr100: linear-gradient(180deg, #907cff 0%, #eed2ff 100%);

$green-gr300: linear-gradient(180deg, #46df38 0%, #25cd16 100%);
$green-gr200: linear-gradient(180deg, #6fed69 0%, #9be938 100%);
$green-gr100: linear-gradient(180deg, #89ff7d 0%, #cbff75 100%);
