.pagination {
  padding: 78px 0;
  position: relative;
  font-family: "Montserrat", sans-serif;

  @include _tablet {
    display: none;
  }
  .sorting {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    gap: 16px;

    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
      padding: 5px 32px 5px 7px;
    }

    &__select {
      border: none !important;
      outline: none !important;
      background-color: transparent;
    }
  }
}
