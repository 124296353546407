.reviews {
  padding-top: 40px;
  position: relative;
  z-index: 20;
  padding-bottom: 78px;
  border-radius: 0 0 85px 85px;
  background-color: $bg-grey;

  @include _tablet {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  &__title {
    margin-bottom: 40px;
    @include _tablet {
      margin-bottom: 20px;
    }
  }
  &__content {
    padding-left: 50px;

    @include _tablet {
      padding-left: 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-height: 520px;
  }

  &__item {
    max-width: 823px;
    width: 100%;
    border: 2px solid rgba(52, 78, 94, 1);
    border-radius: 30px;
    padding: 20px;
    &.skeleton {
      height: 300px;
      background: #fff;
    }

    @include _tablet {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &__item-info {
    font-weight: 500;
    text-align: left;
    margin-bottom: 32px;

    @include _tablet {
      margin: 0;
      margin-top: 32px;
    }
  }

  &__item-date {
  }

  &__item-author {
  }

  &__item-text {
  }

  .infinity-scroll {
    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-height: 520px;
    }
  }
}
