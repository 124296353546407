.ui-field {
  &__input {
    width: 100%;
    height: 48px;
    margin-bottom: 16px;
    padding: 12px 8px 12px 18px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 8px;
    border: 0;
    outline: none;
  }
}
