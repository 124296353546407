.home-questions {
  padding-top: 64px;

  @include _phablet {
    padding-top: 48px;
  }
  .ui-title {
    margin-bottom: 50px;

    @include _phablet {
      margin-bottom: 36px;
    }
  }
}
