.map {
  display: flex;
  width: 569px;
  height: 454px;
  justify-content: space-between;
  border-radius: 30px;
  overflow: hidden;

  @include _tablet {
    width: 100%;
  }

  .yandex-map {
    width: 100%;
  }
}
