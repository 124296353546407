.home-services {
  position: relative;
  padding-bottom: 85px;
  z-index: 30;
  border-radius: 0 0 85px 85px;
  background: $bg-grey;

  @include _tablet {
    padding-bottom: 32px;
    border-radius: 0;
  }

  &__head {
    padding: 20px 40px;
    background-color: $white;
    border-radius: 12px;
    margin-bottom: 64px;

    @include _tablet {
      margin-bottom: 16px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;

    @include _tablet {
      gap: 16px;
    }
  }

  &__block {
    max-width: 484px;
    width: 100%;
    padding: 28px 43px 20px;
    font-size: $fz-base;
    background-color: $white;
    border: 2px solid $white;
    border-radius: 30px;
    transition: border-color 0.2s ease-in-out;
    cursor: pointer;
    user-select: none;

    @include _tablet {
      padding: 20px 16px;
    }
    button {
      margin-top: 24px;
    }
    a {
      height: 100%;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      color: $grayscale-500;

      &:hover {
        opacity: 1;
      }
    }
    &:hover {
      border-color: $red-200;
    }
    span {
      margin-right: 7px;
    }
    p {
      width: 100%;
      line-height: 1.4;
    }
  }

  &__block-title {
    width: 100%;
    text-align: start;
    color: $primary;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 32px;
    line-height: 40px;
  }

  &__price {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 13px;

    @include _tablet {
      flex-direction: column-reverse;
    }
  }

  &__banner {
    max-width: 484px;
    max-height: 288px;
    border-radius: 30px;
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
