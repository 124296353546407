.category-page-mobile {
  &__title {
    background-color: $white;
    padding: 20px 16px;
    margin-bottom: 16px;
  }

  &__head-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    color: $primary;
    margin-bottom: 32px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__info-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  &__ceil {
    background: $white;
    padding: 20px 16px;
    border-radius: 30px;
    margin-bottom: 16px;
  }

  &__description {
  }

  &__block {
    background-color: $white;
    padding: 20px 16px;
    margin-bottom: 16px;
    border-radius: 30px;
  }

  &__block-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__block-subtitle {
    font-weight: 400;
  }

  &__list {
    list-style: inside;
  }

  &__item {
    list-style: inside;
  }

  &__time {
    padding: 20px 16px;
    border: 1px solid $red-200;
  }

  &__time-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 32px;
    color: $primary;
  }

  &__time-text {
  }
}
