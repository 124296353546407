.home-teams {
  font-family: "Montserrat", sans-serif;
  padding-top: 180px;
  width: 100%;
  background-color: $white;
  border-radius: 0;
  margin-top: -85px;

  @include _tablet {
    .container {
      padding: 0;
    }
  }
  @include _desktop {
    margin-top: 0;
    border-radius: 0;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  &__title {
    max-width: 908px;
    font-size: 42px;
    line-height: 48px;
    font-weight: 700;
    color: $primary;
    margin-bottom: 57px;

    @include _tablet {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: $primary;
      margin: 0 16px 57px;
    }
  }
  .banner {
    margin-bottom: 107px;

    @include _desktop {
      margin-bottom: 48px;
    }

    @include _tablet {
      margin: 0 16px 48px;
    }
  }
  &__slider {
    padding-bottom: 70px;

    @include _tablet {
      padding-bottom: 0;
      .home-teams__slide-list,
      .home-teams__slide-name,
      .home-teams__slide-block {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
    }

    .slick-active.slick-center {
      .home-teams__slide-list,
      .home-teams__slide-name,
      .home-teams__slide-block {
        opacity: 1;
      }

      .home-teams__slide-preview {
        img {
          height: 100%;
        }
      }
    }

    .slick-track {
      height: 418px;

      @include _tablet {
        height: 100%;
      }
    }
  }

  &__slide {
    display: flex !important;
    gap: 48px;
    height: 418px;
    padding: 0 10px;

    @include _tablet {
      flex-direction: column !important;
      height: 100%;
      gap: 24px;
    }
  }

  &__slide-preview {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 90%;
      object-fit: cover;
      border-radius: 30px;
      transition: all 0.2s ease-in-out;
    }
    @include _tablet {
      height: 350px;
    }
  }

  &__slide-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 18px;

    @include _tablet {
      padding-top: 0;
    }
  }

  &__slide-info {
    width: 100%;
  }

  &__slide-name {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 16px;
    color: $primary;

    @include _tablet {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: $text;
    }
  }

  &__slide-list {
    &.mobile {
      margin-bottom: 24px;
      li {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    li {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__slide-block {
    max-width: 458px;
    padding: 30px;
    background-color: $blue-100;
    border-radius: 30px;
    @include _tablet {
      width: 100%;
    }
    p {
      width: 205px;
      margin-bottom: 40px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;

      @include _tablet {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 16px;
      }
    }
  }

  &__slide-link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $violet-200;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  &__slide-arrow {
    width: 8px;
    height: 25px;
  }
  .slick-dots {
    height: 16px;
    position: absolute;
    bottom: -48px;
  }
  .custom-dots {
    li {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #d7e8ff;
      overflow: hidden;
      &.slick-active {
        background: $primary;
      }
    }
  }
  .custom-dot::before {
    display: none;
    content: "";
  }

  .custom-next-arrow,
  .custom-prev-arrow {
    @include no-btn();

    z-index: 100;
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    &:hover {
      background: $blue-100;
    }
    .slider-arrow {
      width: 13px;
      height: 30px;
    }
  }
  .custom-next-arrow {
    right: -40px;
  }
  .custom-prev-arrow {
    left: -40px;
    .slider-arrow {
      transform: rotate(180deg);
    }
  }
}
