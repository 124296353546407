.contacts {
  padding-top: 40px;
  @include _phablet {
    padding-top: 20px;
  }

  .ui-title {
    @include _phablet {
      margin-bottom: 20px;
    }
  }
}
