.schedule-mobile {
  width: 100%;
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    width: 100%;
    padding: 16px;
    background: $primary;
    display: grid;
    gap: 8px;
    grid-template-areas:
      "category date"
      "day_type day_type"
      "address address"
      "btn btn";
  }

  &__ceil {
    padding: 12px 16px;
    background-color: $white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;

    .ui-buttom {
      width: 100%;
    }
  }

  &__btn {
    grid-area: btn;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }
  .category {
    grid-area: category;
  }
  .date {
    grid-area: date;
  }
  .day_type {
    grid-area: day_type;
  }
  .address {
    grid-area: address;
  }
}
